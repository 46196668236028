import React from "react"
import Layout from "../components/Layout"
import { useTranslation } from "react-i18next"
import { graphql } from "gatsby"
import Engrenagem from "../images/icons8-serviços.svg"


const Manutencao = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <div style={{ padding: "5%", display: "flex", justifyContent: "center", alignItems: "center", gap: "2%" }}>
        <h1 style={{ textTransform: "uppercase", textAlign: "center" }}>Página em manutenção</h1>
        <Engrenagem />
      </div>
    </Layout>
  )
}

export default Manutencao

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["home", "products", "services", "store"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`
